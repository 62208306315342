html.swipebox-html.swipebox-touch {
    overflow: hidden!important
}

#swipebox-overlay img {
    border: none!important
}

#swipebox-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999!important;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

#swipebox-slider {
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    white-space: nowrap;
    position: absolute;
    display: none
}

#swipebox-slider .slide {
    background:url(/++theme++ajpinedademar/modules/swipebox/loader.gif) no-repeat center center;
    height: 100%;
    width: 100%;
    line-height: 1px;
    text-align: center;
    display: inline-block
}

#swipebox-slider .slide:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 1px;
    margin-right: -1px
}

#swipebox-slider .slide .swipebox-video-container,
#swipebox-slider .slide img {
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    vertical-align: middle
}

#swipebox-slider .slide .swipebox-video-container {
    background: 0 0;
    max-width: 1140px;
    max-height: 100%;
    width: 100%;
    padding: 5%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box
}

#swipebox-slider .slide .swipebox-video-container .swipebox-video {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    position: relative
}

#swipebox-slider .slide .swipebox-video-container .swipebox-video iframe {
    width: 100%!important;
    height: 100%!important;
    position: absolute;
    top: 0;
    left: 0
}

#swipebox-action,
#swipebox-caption {
    position: absolute;
    left: 0;
    z-index: 999;
    height: 50px;
    width: 100%
}

#swipebox-action {
    bottom: -50px
}

#swipebox-action.visible-bars {
    -ms-transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px)
}

#swipebox-action #swipebox-close,
#swipebox-action #swipebox-next,
#swipebox-action #swipebox-prev {
    background-image:url(/++theme++ajpinedademar/modules/swipebox/icons.png);
    background-repeat: no-repeat;
    border: none!important;
    text-decoration: none!important;
    cursor: pointer;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0
}

#swipebox-action #swipebox-prev {
    background-position: -32px 13px;
    right: 100px
}

#swipebox-action #swipebox-next {
    background-position: -78px 13px;
    right: 40px
}

#swipebox-action #swipebox-close {
    background-position: 15px 12px;
    left: 40px
}

#swipebox-action #swipebox-next.disabled,
#swipebox-action #swipebox-prev.disabled {
    opacity: .3
}

#swipebox-caption {
    top: -50px;
    text-align: center
}

#swipebox-caption.visible-bars {
    -ms-transform: translateY(50px);
    -webkit-transform: translateY(50px);
    transform: translateY(50px)
}

#swipebox-slider.rightSpring {
    -webkit-animation: rightSpring .3s;
    animation: rightSpring .3s
}

#swipebox-slider.leftSpring {
    -webkit-animation: leftSpring .3s;
    animation: leftSpring .3s
}

@-webkit-keyframes rightSpring {
    0% {
        margin-left: 0
    }
    50% {
        margin-left: -30px
    }
    100% {
        margin-left: 0
    }
}

@keyframes rightSpring {
    0% {
        margin-left: 0
    }
    50% {
        margin-left: -30px
    }
    100% {
        margin-left: 0
    }
}

@-webkit-keyframes leftSpring {
    0% {
        margin-left: 0
    }
    50% {
        margin-left: 30px
    }
    100% {
        margin-left: 0
    }
}

@keyframes leftSpring {
    0% {
        margin-left: 0
    }
    50% {
        margin-left: 30px
    }
    100% {
        margin-left: 0
    }
}

@media screen and (max-width:800px) {
    #swipebox-action #swipebox-close {
        left: 0
    }
    #swipebox-action #swipebox-prev {
        right: 60px
    }
    #swipebox-action #swipebox-next {
        right: 0
    }
}

#swipebox-overlay {
    background: #0d0d0d
}

#swipebox-action,
#swipebox-caption {
    text-shadow: 1px 1px 1px #000;
    background-color: #0d0d0d;
    background-color: #0d0d0d;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#0d0d0d), to(#000));
    background-image: -webkit-linear-gradient(top, #0d0d0d, #000);
    background-image: linear-gradient(to bottom, #0d0d0d, #000);
    opacity: .95
}

#swipebox-action {
    border-top: 1px solid rgba(255, 255, 255, .2)
}

#swipebox-caption {
    border-bottom: 1px solid rgba(255, 255, 255, .2);
    color: #fff!important;
    font-size: 15px;
    line-height: 43px;
    font-family: Helvetica, Arial, sans-serif
}